.button {
  font-family: $text;
  font-size: 1.8rem;
  line-height: 1;
  background-color: $blue;
  color: $white;
  padding: 1.2rem 1.6rem 1.1rem;
  border: 0.1rem solid $blue;
  border-radius: 0.4rem;
  display: inline-block;
  transition: 0.25s ease all;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: darken($blue, 7%);
  }

  &--alt {
    background-color: $darkblue;

    &:hover,
    &:focus {
      background-color: darken($darkblue, 7%);
    }
  }

  &--alt-2 {
    background-color: transparent;
    border-color: $white;
  }

  &[disabled] {
    background-color: $darkblue;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
