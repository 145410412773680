.upper-header {
  background-color: $upper-header-color;
  padding: 1rem 0;
  margin-bottom: 3.5rem;

  @media screen and (max-width: $nav-break) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }

  &__list {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $phone) {
      justify-content: space-between;
    }
  }

  &__item {
    position: relative;
    margin-left: 3rem;

    &:first-child {
      margin-left: 0;
    }

    @media screen and (max-width: $under-tablet) {
      margin-left: 2.2rem;
    }

    &--helpdesk {
      margin-left: 2.5rem;
      padding-left: 2.5rem;

      @media screen and (max-width: $under-tablet) {
        padding-left: 0;
        margin-left: 2.2rem;
      }

      &::before {
        content: "";
        width: 0.1rem;
        height: 2.2rem;
        background-color: rgba(24, 38, 65, 0.2);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        @media screen and (max-width: $under-tablet) {
          display: none;
        }
      }
    }
  }

  &__link {
    font-size: 1.6rem;
    position: relative;
    color: $black;

    @media screen and (max-width: $phone) {
      font-size: 1.5rem;
    }

    .upper-header__item--helpdesk & {
      color: $blue;
      padding-left: 2.8rem;

      @media screen and (max-width: $under-tablet) {
        padding-left: 0;
      }

      &::before {
        content: "\e901";
        font-family: "paperless", sans-serif;
        font-size: 1.4rem;
        position: absolute;
        left: 0;
        top: 0.3rem;
        width: 2.1rem;
        height: 1.4rem;
        display: block;

        @media screen and (max-width: $under-tablet) {
          display: none;
        }
      }
    }

    &:hover,
    &:focus {
      color: $blue;

      .upper-header__item--helpdesk & {
        color: $black;
      }
    }
  }
}