.navigation {
  display: flex;
  align-items: center;

  @media screen and (max-width: $nav-break) {
    flex-flow: column wrap;
    align-items: flex-start;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    @media screen and (max-width: $nav-break) {
      position: fixed;
      top: 4.3rem;
      right: 0;
      width: 44rem;
      background: linear-gradient(90deg, rgba(11, 36, 84, 1) 0%, rgba(22, 72, 168, 1) 100%);
      z-index: 100;
      opacity: 0;
      visibility: hidden;
      transition: 0.25s ease all;
      transform: translateX(100%);
      padding: 4rem 4rem 3rem;
      display: block;

      &.open {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }

      &.up {
        top: 0;
      }
    }

    @media screen and (max-width: $under-tablet) {
      width: 100%;
    }
  }

  &__item {
    margin-left: 4rem;

    @media screen and (max-width: $nav-break) {
      margin-left: 0;
      margin-bottom: 1.5rem;
    }
  }

  &__link {
    position: relative;
    color: $black;
    transition: 0.25s ease color, 0.25s ease background;
    padding: 0.5rem 0;
    display: inline-block;

    .header--white & {
      color: $white;
    }

    @media screen and (max-width: $nav-break) {
      color: $white;
    }

    &::after {
      content: "";
      width: 2.5rem;
      height: 0.2rem;
      background-color: $blue;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      visibility: hidden;
      transition: 0.25s ease all;

      .header--white & {
        background-color: $white;
      }

      @media screen and (max-width: $nav-break) {
        left: 0;
        transform: none;
      }
    }

    .navigation__item--cta & {
      padding: 0.9rem 1.4rem;
      border: 0.1rem solid $black;
      border-radius: 0.4rem;

      &::after {
        display: none;
      }

      .header--white & {
        border-color: $white;
      }

      @media screen and (max-width: $nav-break) {
        border-color: $white;
      }
    }

    &:hover,
    &:focus {
      color: $blue;

      .header--white & {
        color: darken($white, 20%);
      }

      @media screen and (max-width: $nav-break) {
        color: darken($white, 15%);
      }

      &::after {
        opacity: 1;
        visibility: visible;
      }

      .navigation__item--cta & {
        color: $white;
        background-color: $blue;

        .header--white & {
          color: $blue;
          background-color: $white;
        }

        @media screen and (max-width: $nav-break) {
          color: $blue;
          background-color: $white;
        }
      }
    }
  }
}