.advantages {
  position: relative;
  padding: 1rem 0 2rem;
  background-color: $white;
  z-index: 1;
  overflow: hidden;

  @media screen and (max-width: $desktop) {
    padding: 0 0 7rem;
  }

  @media screen and (max-width: $under-tablet) {
    padding: 0 0 6rem;
  }

  &__surtitle {
    font-size: 2rem;
    font-weight: $weight-semibold;
    color: $blue;
    margin-bottom: 1.7rem;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.8rem;
      margin-bottom: 1.4rem;
    }
  }

  &__title {
    margin-bottom: 7rem;

    @media screen and (max-width: $desktop) {
      margin-bottom: 5rem;
    }

    @media screen and (max-width: $under-tablet) {
      margin-bottom: 3.5rem;
    }
  }

  &__list {
    margin: 0 -6.6rem -7rem;

    @media screen and (max-width: $desktop-larger) {
      margin: 0 -3.5rem -6rem;
    }

    @media screen and (max-width: $desktop) {
      margin: 0 0 -4rem;
    }

    @media screen and (max-width: $big-phone) {
      margin: 0 0 -3.5rem;
    }

    // clearfix
    &::after {
      content: "";
      clear: both;
      display: table;
    }
  }
}