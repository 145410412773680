.hero {
  padding-bottom: 12rem;
  overflow: hidden;

  @media screen and (max-width: $very-large-desktop) {
    padding-bottom: 9rem;
  }

  @media screen and (max-width: $large-desktop) {
    padding-bottom: 7.5rem;
  }

  @media screen and (max-width: $under-tablet) {
    padding-bottom: 4.5rem;
  }

  &__inner {
    display: flex;
    align-items: center;

    @media screen and (max-width: $under-tablet) {
      display: block;
    }
  }

  &__content {
    max-width: 55.6rem;
    margin-left: 8.4%;
    margin-right: 6.5rem;
    flex: 1;

    @media screen and (max-width: $very-large-desktop) {
      margin-left: 0;
    }

    @media screen and (max-width: $large-desktop) {
      margin-right: 4rem;
    }

    @media screen and (max-width: $under-tablet) {
      margin-right: 0;
    }
  }

  &__title {
    font-size: 4.2rem;
    margin-bottom: 1.2rem;

    @media screen and (max-width: $large-desktop) {
      font-size: 3.6rem;
    }

    @media screen and (max-width: $under-tablet) {
      font-size: 3.4rem;
    }

    @media screen and (max-width: $phone) {
      font-size: 3.1rem;
    }
  }

  &__subtitle {
    font-size: 2rem;
    font-weight: $weight-regular;
    line-height: 1.5;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.8rem;
    }
  }

  &__button {
    margin-top: 2rem;
  }

  &__image {
    order: 2;

    @media screen and (max-width: $very-large-desktop) {
      margin-right: -25%;
    }

    @media screen and (max-width: $large-desktop) {
      margin-right: -33.33%;
    }

    @media screen and (max-width: $desktop-larger) {
      margin-right: -50%;
    }

    @media screen and (max-width: $desktop) {
      margin-right: -75%;
    }

    @media screen and (max-width: $under-tablet) {
      margin-right: 0;
      margin-bottom: 2.5rem;
      padding: 1rem;
    }

    img {
      width: 100%;
      min-width: 80rem;
      height: auto;
      box-shadow: 0 0 1.5rem rgba(24, 38, 65, 0.25);

      @media screen and (max-width: $very-large-desktop) {
        margin-right: 0;
      }

      @media screen and (max-width: $desktop-larger) {
        min-width: 70rem;
      }

      @media screen and (max-width: $under-tablet) {
        min-width: inherit;
        max-width: 46rem;
        border: 1px solid;
        border-radius: 0.4rem;
        border-color: rgb(213, 220, 226) rgb(213, 220, 226) rgb(184, 194, 204);
        transform: perspective(75em) rotateY(-18deg) translateX(-.75rem);
        box-shadow: rgba(24, 38, 65, 0.42) 0px 16px 32px -8px, rgba(24, 38, 65, 0.08) 0px 8px 18px -8px;
      }
    }
  }
}