/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Basic Sans Semi Bold by Latinotype
 * URL: https://www.myfonts.com/products/semi-bold-basic-sans-170507
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Basic Sans Regular by Latinotype
 * URL: https://www.myfonts.com/products/regular-basic-sans-170489
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * © 2023 MyFonts Inc. */



@font-face {
  font-family: "basic-sans";
  font-weight: 400;
  src:  url('../../fonts/BasicSansRegular/font.woff2') format('woff2'),
        url('../../fonts/BasicSansRegular/font.woff') format('woff');
}
@font-face {
  font-family: "basic-sans";
  font-weight: 600;
  src:  url('../../fonts/BasicSansSemiBold/font.woff2') format('woff2'),
        url('../../fonts/BasicSansSemiBold/font.woff') format('woff');
}

