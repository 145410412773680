.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @media screen and (max-width: $nav-break) {
    padding-top: 8rem;
  }

  @media screen and (max-width: $big-phone) {
    padding-top: 7.4rem;
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
  }

  &__brand {
    width: 10.7rem;
    height: 6.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    background-image: url("../img/logo.svg");

    @media screen and (max-width: $under-tablet) {
      width: 8.6rem;
      height: 5rem;
    }

    .header--white & {
      background-image: url("../img/logo-white.svg");
    }
  }
}