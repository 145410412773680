.lang-switcher {
  margin-left: 5rem;

  @media screen and (max-width: $nav-break) {
    margin: 3rem 0 0;
    display: flex;
  }

  &__current {
    position: relative;
    padding-right: 1.6rem;

    @media screen and (max-width: $nav-break) {
      font-weight: $weight-semibold;
      padding-right: 0;
    }

    &::after {
      content: "\e900";
      font-family: "paperless", sans-serif;
      font-size: 1rem;
      position: absolute;
      top: 0.7rem;
      right: 0;

      @media screen and (max-width: $nav-break) {
        display: none;
      }
    }
  }

  &__list {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: 0.25s ease all;
    margin-top: 0.3rem;

    @media screen and (max-width: $nav-break) {
      position: relative;
      margin-top: 0;
      display: flex;
      opacity: 1;
      visibility: visible;
    }

    [aria-expanded="true"] + & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__item {
    margin-bottom: 0.3rem;

    @media screen and (max-width: $nav-break) {
      margin: 0 0 0 2rem;
    }
  }

  &__link {
    color: $black;

    .header--white & {
      color: $white;
    }

    @media screen and (max-width: $nav-break) {
      color: $white;
    }

    &:hover,
    &:focus {
      color: $blue;

      .header--white & {
        color: darken($white, 20%);
      }

      @media screen and (max-width: $nav-break) {
        color: darken($white, 20%);
      }
    }
  }
}