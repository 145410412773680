.page-404 {
  padding: 24rem 0 14rem;
  text-align: center;

  @media screen and (max-width: $under-tablet) {
    padding-top: 22rem;
  }

  &__title {
    font-size: 12rem;

    @media screen and (max-width: $under-tablet) {
      font-size: 10rem;
    }
  }

  &__text {
    font-size: 2rem;
    max-width: 64rem;
    margin: 1rem auto 0;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.8rem;
    }
  }

  &__button {
    margin: 4rem 0 0;

    @media screen and (max-width: $under-tablet) {
      margin-top: 3rem;
    }
  }
}