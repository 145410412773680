input,
textarea {
  font-family: $text;

  &:focus-visible {
    outline: 0.1rem dashed $black;
  }
}

::-webkit-input-placeholder { /* Edge */
  color: $placeholder-color;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $placeholder-color;
}

::placeholder {
  color: $placeholder-color;
}

.form {
  background-color: $blue;
  padding: 4.5rem;
  border-radius: 1.6rem;
  box-shadow: 0 0 1.6rem rgba(0, 0, 0, 0.16);

  @media screen and (max-width: $under-tablet) {
    padding: 3.5rem 3rem;
  }

  &-control {
    &.controllo {
      position: absolute;
      visibility: hidden;
      height: 0;
      margin: 0;
      padding: 0;
      border: 0;
    }
  }

  &-field {
    font-size: 1.8rem;
    width: 100%;
    padding: 1rem 1.4rem 0.9rem;
    border-radius: 0.4rem;
    border: 0.1rem solid rgba(18, 63, 73, 0.3);
    margin-bottom: 1.5rem;
  }

  &-check-input {
    display: none;
  }

  &-check-label {
    margin-top: 1rem;
    position: relative;
    color: $white;
    padding-left: 2.8rem;
    line-height: 1.2;
    display: inline-block;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.7rem;
    }

    &::before {
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 0.3rem;
      background-color: $white;
      position: absolute;
      left: 0;
      top: 0.2rem;
    }

    &::after {
      content: "\e90e";
      font-family: "paperless", sans-serif;
      font-size: 2.8rem;
      border-radius: 0.2rem;
      color: $darkblue;
      position: absolute;
      left: -0.4rem;
      top: -0.6rem;
      opacity: 0;
    }

    .form-check-input:checked + & {
      &::after {
        opacity: 1;
      }
    }
  }

  &-info {
    font-size: 1.5rem;
    line-height: 1.5;
    color: $white;
    margin-top: 3rem;

    @media screen and (max-width: $under-tablet) {
      line-height: 1.4;
    }
  }

  &-button {
    margin-top: 2.4rem;
  }

  &-validation {
    font-size: 1.6rem;
    font-weight: $weight-semibold;
    margin: 3rem 0 0;
    padding: 1rem 1.5rem;
    background: white;
    border-radius: 0.4rem;
    display: none;
    color: $blue;

    &.error {
      color: #a81616;
    }
  }
}