$title: "basic-sans", sans-serif;
$text: $title;

/** Font weights */
$weight-regular: 400;
$weight-semibold: 600;

/** Colors */
$white: #fff;
$black: #182641;
$gray: #465167;
$darkblue: #0b2454;
$blue: #1648a8;
$lightgray: #f6f6f7;
$upper-header-color: #f8f9fa;
$placeholder-color: #768b99;

/** Mediaquery breakpoints */
$very-large-desktop: 100em;
$large-desktop: 90em;
$desktop-larger: 80em;
$nav-break: $desktop-larger;
$desktop: 68em;
$tablet: 48em;
$under-tablet: 46em;
$big-phone: 30em;
$phone: 22em;