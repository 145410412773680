.legal {
  &__inner {
    padding: 5.4rem 0 9rem;

    @media screen and (max-width: $under-tablet) {
      padding: 4rem 0 5rem;
    }
  }

  &__header {
    padding: 21.3rem 0 3.2rem;
    background: linear-gradient(90deg, rgba(11, 36, 84, 1) 0%, rgba(22, 72, 168, 1) 100%);
    text-align: center;
    color: $white;

    @media screen and (max-width: $under-tablet) {
      padding-top: 19.5rem;
    }
  }

  &__section {
    margin-top: 8rem;

    @media screen and (max-width: $under-tablet) {
      margin-top: 6rem;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__page-title {
    margin-bottom: 1.7rem;

    @media screen and (max-width: $under-tablet) {
      margin-bottom: 1.4rem;
    }
  }

  &__page-description {
    @media screen and (max-width: $under-tablet) {
     font-size: 1.6rem;
    }
  }
}