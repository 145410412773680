.blog {
  padding-top: 21rem;

  @media screen and (max-width: $under-tablet) {
    padding-top: 19.5rem;
  }

  &::before {
    content: "";
    width: 100%;
    height: 50rem;
    background: linear-gradient(90deg, rgba(11, 36, 84, 1) 0%, rgba(22, 72, 168, 1) 100%);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: -1;
  }

  &__title {
    text-align: center;
    color: $white;
    margin-bottom: 4.5rem;

    @media screen and (max-width: $desktop) {
      margin-bottom: 4rem;
    }

    @media screen and (max-width: $under-tablet) {
      margin-bottom: 3.5rem;
    }
  }

  &__list {
    margin: 0 -0.8rem 8rem;
    display: flex;
    flex-flow: row wrap;

    @media screen and (max-width: $desktop) {
      margin: 0 0 4rem;
    }
  }
}