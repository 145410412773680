.news-item {
  width: 50%;
  padding: 0 0.8rem;
  margin-bottom: 1.6rem;

  @media screen and (max-width: $desktop) {
    width: 100%;
    max-width: 68rem;
    margin: 0 auto 1.6rem;
    padding: 0;
  }

  .home & {
    position: relative;
    width: auto;
    padding: 0;
    z-index: 5;
  }

  &__link {
    border-radius: 0.6rem;
    background-color: $white;
    display: block;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.16);
    padding: 2.8rem 2.4rem 2.2rem 2.4rem;
    color: $black;
    transition: box-shadow 0.25s ease;

    @media screen and (max-width: $under-tablet) {
      padding: 2rem 1.6rem 1.8rem;
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.3);
    }
  }

  &__date {
    font-size: 2rem;
    font-weight: $weight-semibold;
    display: inline-block;
    margin-bottom: 1.3rem;
    color: $blue;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.9rem;
      margin-bottom: 1rem;
    }
  }

  &__title {
    font-size: 2.8rem;
    line-height: 1;
    margin-bottom: 1.8rem;
    transition: color 0.25s ease;

    @media screen and (max-width: $under-tablet) {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }

    .news-item__link:hover & {
      color: $blue;
    }
  }

  &__excerpt {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 4.4rem;

    @media screen and (max-width: $under-tablet) {
      margin-bottom: 3rem;
    }
  }

  &__more {
    position: relative;
    font-size: 1.6rem;
    font-weight: $weight-semibold;
    color: $blue;
    padding-right: 1.5rem;
  }

  &__icon {
    font-size: 0.9rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}