.socials {
  display: flex;

  &__item {
    margin-right: 1.4rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    color: $white;

    &:hover,
    &:focus {
      color: darken($white, 20%);
    }
  }

  &__icon {
    font-size: 2rem;
  }
}