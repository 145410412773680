.about {
  position: relative;
  padding: 8rem 0 13.1rem;
  background: linear-gradient(90deg, rgba(11, 36, 84, 1) 0%, rgba(22, 72, 168, 1) 100%);
  color: $white;

  @media screen and (max-width: $desktop) {
    padding: 6rem 0;
  }

  @media screen and (max-width: $under-tablet) {
    padding: 4.5rem 0;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 10rem;
    transform: skewY(2.5deg);
    bottom: -5.8rem;
    left: 0;
    background-color: $white;
    z-index: 1;
  }

  &__list {
    display: flex;
    margin: 0 -3.4rem;

    @media screen and (max-width: $desktop-larger) {
      margin: 0 -2.6rem;
    }

    @media screen and (max-width: $desktop) {
      margin: 0 -2rem;
      justify-content: center;
      flex-flow: row wrap;
    }

    @media screen and (max-width: $under-tablet) {
      display: block;
      margin: 0;
    }
  }
}