.format-text {
  .columns {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -4.5rem 4rem;

    @media screen and (max-width: $under-tablet) {
      display: block;
      margin: 0 0 3rem;
    }

    h2 {
      margin-top: 0;
    }
  }

  .column {
    padding: 0 4.5rem 3rem;

    @media screen and (max-width: $under-tablet) {
      padding: 0 0 2.5rem;
    }
  }

  h2 {
    position: relative;
    font-size: 2.8rem;
    padding-bottom: 1.8rem;
    margin-bottom: 2.1rem;
    margin-top: 7rem;

    @media screen and (max-width: $under-tablet) {
      font-size: 2.4rem;
      margin-top: 4.5rem;
      padding-bottom: 1.6rem;
      margin-bottom: 1.6rem;
    }

    &:first-child {
      margin-top: 0;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 2.5rem;
      height: 0.2rem;
      background-color: $blue;
    }
  }

  h3 {
    font-size: 2.4rem;
    margin-bottom: 1rem;

    @media screen and (max-width: $under-tablet) {
      font-size: 2.2rem;
      margin-bottom: 0.8rem;
    }
  }

  h4 {
    font-size: 2rem;
    margin-bottom: 1rem;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.9rem;
      margin-bottom: 0.8rem;
    }
  }

  h5,
  h6 {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.7rem;
      margin-bottom: 0.6rem;
    }
  }

  .address {
    line-height: 1.6;
    font-style: normal;
  }

  p {
    line-height: 1.6;
    max-width: 95rem;
  }

  a {
    font-weight: $weight-semibold;
    color: $blue;
    text-decoration: underline;
    text-underline-offset: 0.3rem;

    &:hover,
    &:focus {
      color: lighten($blue, 15%);
    }
  }

  ul,
  ol {
    margin: 1.5rem 0 3rem;
  }

  ul {
    li {
      position: relative;
      padding-left: 1.6rem;
      margin: 0 0 1.4rem;

      &::before {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        background-color: $blue;
        position: absolute;
        left: 0.2rem;
        top: 0.9rem;
        display: block;
        border-radius: 50%;
      }
    }
  }

  ol {
    li {
      counter-increment: list;
      position: relative;
      padding-left: 2.5rem;
      margin: 0 0 1.4rem;

      &::before {
        content: counter(list) ".";
        color: $blue;
        font-weight: $weight-semibold;
        position: absolute;
        left: 2rem;
        top: 0;
        transform: translateX(-100%);
        display: block;
      }
    }
  }

  img {
    max-width: 100%;
    display: block;
    cursor: pointer;

    @media screen and (max-width: $big-phone) {
      width: 100%;
    }
  }

  picture {
    display: block;
    margin-bottom: 3rem;

    & + h2 {
      margin-top: 4rem;
    }
  }

  .stand-out {
    font-size: 2rem;
    font-weight: $weight-semibold;
  }
}