.functionality {
  width: 50%;
  padding: 10rem 6.6rem 6rem;

  @media screen and (max-width: $desktop-larger) {
    padding: 10rem 2rem 5rem;
  }

  @media screen and (max-width: $desktop) {
    width: 100%;
    padding: 10rem 0 4rem;
  }

  &:nth-child(4) {
    margin-top: -6.5rem;

    @media screen and (max-width: $desktop) {
      margin-top: 0;
    }
  }

  &--more {
    @media screen and (max-width: $desktop) {
      padding-top: 0;
      margin-top: 1rem;
    }
  }

  &__inner {
    position: relative;
    background-color: $lightgray;
    padding: 3rem 3rem 2.8rem 3rem;
    border-radius: 0.8rem;

    @media screen and (max-width: $under-tablet) {
      padding: 2.5rem 2.2rem 2.2rem 2.2rem;
    }
  }

  &__image {
    height: 27rem;
    margin: -13rem auto 3.3rem;
    display: block;

    @media screen and (max-width: $large-desktop) {
      height: 21rem;
    }

    @media screen and (max-width: $big-phone) {
      height: 18rem;
    }
  }

  &__title {
    position: relative;
    font-size: 2.8rem;
    padding-bottom: 1.8rem;
    margin-bottom: 1.6rem;

    @media screen and (max-width: $under-tablet) {
      font-size: 2.4rem;
      padding-bottom: 1.6rem;
      margin-bottom: 1.4rem;
    }

    &::after {
      content: "";
      width: 2.5rem;
      height: 0.2rem;
      background-color: $blue;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__text {
    font-size: 1.6rem;

    p {
      margin-bottom: 2.2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}