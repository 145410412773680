.contact {
  padding: 8rem 0;

  @media screen and (max-width: $desktop) {
    padding: 5.5rem 0;
  }

  @media screen and (max-width: $under-tablet) {
    padding: 4.5rem 0;
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $desktop) {
      justify-content: center;
    }
  }

  &__image {
    position: absolute;
    width: calc(100% - 54rem);
    left: 0;
    top: 0;

    @media screen and (max-width: $desktop) {
      display: none;
    }

    &::before {
      content: "";
      display: block;
      padding-bottom: 72%;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $blue;
      opacity: 0.25;
      border-radius: 0.8rem;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 0.8rem;
    }
  }

  &__content {
    position: relative;
    max-width: 63rem;
    padding-top: 2.5rem;
    z-index: 5;

    @media screen and (max-width: $desktop) {
      padding-top: 0;
    }
  }

  &__title {
    padding-left: 14rem;
    margin-bottom: 3.2rem;

    @media screen and (max-width: $desktop) {
      margin-bottom: 2.8rem;
      padding-left: 0;
      text-align: center;
    }
  }

  &__support {
    margin-top: 2.8rem;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.6rem;
      margin-top: 2rem;
    }

    a {
      font-weight: $weight-semibold;
      color: $blue;
      text-decoration: underline;
      text-underline-offset: 0.3rem;

      &:hover,
      &:focus {
        color: $darkblue
      }
    }
  }
}