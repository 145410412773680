.news {
  padding: 7rem 0;
  background: linear-gradient(90deg, rgba(11, 36, 84, 1) 0%, rgba(22, 72, 168, 1) 100%);
  color: $white;
  overflow: hidden;

  @media screen and (max-width: $desktop) {
    padding: 5.5rem 0;
  }

  @media screen and (max-width: $under-tablet) {
    padding: 4.5rem 0;
  }

  &__title {
    text-align: center;
    margin-bottom: 4rem;

    @media screen and (max-width: $desktop) {
      margin-bottom: 2.8rem;
    }
  }

  &__news {
    position: relative;
    max-width: 54rem;
    margin: 0 auto;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 0.6rem;
      background-color: #c4cfe5;
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      z-index: 1;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 0.6rem;
      background-color: #89a0cb;
      position: absolute;
      top: 3rem;
      left: 3rem;
      z-index: 0;
      opacity: 0.5;
    }
  }

  &__button {
    margin-top: 8rem;

    @media screen and (max-width: $desktop) {
      margin-top: 6.5rem;
    }
  }
}