.burger {
  position: fixed;
  width: 4.2rem;
  height: 2.6rem;
  right: 4%;
  top: 8rem;
  background-color: transparent;
  border: none;
  z-index: 110;
  cursor: pointer;
  display: none;

  &:focus-visible {
    outline: 0.1rem dashed $black;
  }

  @media screen and (max-width: $nav-break) {
    display: block;
  }

  @media screen and (max-width: $big-phone) {
    top: 7.4rem;
  }

  &__inner {
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all 0.3s ease;

    .open & {
      transform: rotate(90deg);
      left: 0.1rem;
      top: 0.4rem;
    }
  }

  &__item {
    width: 100%;
    height: 0.3rem;
    background-color: $darkblue;
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    transition: all 0.35s ease;
    border-radius: 1rem;

    .open & {
      background-color: $white;
    }

    &--1 {
      top: 0;
      right: 0;

      .open & {
        top: 50%;
        right: 0.4rem;
        transform: rotate(45deg);
      }
    }

    &--2 {
      top: 50%;
      width: 80%;
      left: 20%;

      .open & {
        opacity: 0;
      }
    }

    &--3 {
      top: 100%;
      right: 0;

      .open & {
        top: 50%;
        right: 0.4rem;
        transform: rotate(-45deg);
      }
    }
  }
}
