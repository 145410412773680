.back {
  color: $white;
  text-decoration: none;
  position: absolute;
  bottom: 0.3rem;
  left: 0;
  padding-left: 1.7rem;
  transition: 0.25s ease all;

  &:hover,
  &:focus {
    color: darken($white, 20%);
    padding-left: 1.4rem;
  }

  &__wrapper {
    position: relative;
    order: 1;

    @media screen and (max-width: $large-desktop) {
      order: 0;
      max-width: 95rem;
      margin-bottom: 2.5rem;
    }
  }

  &__icon {
    font-size: 0.9rem;
    position: absolute;
    left: 0;
    top: 0.7rem;
  }
}
