.single-post {
  &__header {
    padding: 20rem 0 4.5rem;
    background: linear-gradient(90deg, rgba(11, 36, 84, 1) 0%, rgba(22, 72, 168, 1) 100%);
    color: $white;
    display: flex;
    flex-flow: column wrap;

    @media screen and (max-width: $large-desktop) {
      padding-top: 22rem;
    }

    @media screen and (max-width: $under-tablet) {
      padding-bottom: 3.5rem;
    }
  }

  &__content {
    padding: 5.4rem 0 9rem;

    @media screen and (max-width: $under-tablet) {
      padding: 4rem 0 5rem;
    }
  }

  &__date {
    font-size: 2.1rem;
    font-weight: $weight-semibold;
    opacity: 0.7;
    display: inline-block;
    margin-bottom: 1.2rem;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.9rem;
      margin-bottom: 1rem;
    }
  }

  &__page-title {
    margin-bottom: 3.2rem;

    @media screen and (max-width: $large-desktop) {
      margin-bottom: 2.4rem;
    }

    @media screen and (max-width: $under-tablet) {
      margin-bottom: 1.8rem;
    }
  }

  &__single-portrait-image {
    width: 45%;
    margin-right: auto;
    margin-left: auto;
  }

  &__image-row {
    display: flex;

    @media screen and (max-width: $big-phone) {
      display: block;
    }

    .oneoftwo-image {
      max-width: 90%;

      @media screen and (max-width: $big-phone) {
        max-width: 100%
      }
    }

    & + h2 {
      margin-top: 4rem;
    }
  }

}