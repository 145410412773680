// Webfonts
@import "includes/webfonts";

// Common stuff
@import "common/variables";
@import "common/global";
@import "common/format-text";
@import "common/antispam";
@import "common/icons";

// Components
@import "components/upper-header";
@import "components/header";
@import "components/navigation";
@import "components/lang-switcher";
@import "components/burger";
@import "components/footer";
@import "components/socials";
@import "components/footer-navigation";
@import "components/button";
@import "components/news-item";
@import "components/back";
@import "components/about-item";
@import "components/advantage";
@import "components/form";
@import "components/functionality";

// Pages
@import "pages/page-404";
@import "pages/blog";
@import "pages/legal";
@import "pages/single-post";
@import "pages/home";

// Home Sections & Components
@import "home/hero";
@import "home/about";
@import "home/advantages";
@import "home/functionalities";
@import "home/news";
@import "home/contact";

// Libraries
@import "libs/magnific-popup";
