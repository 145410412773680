@font-face {
  font-family: "paperless";
  src: url("../fonts/paperless.ttf?89srtu") format("truetype"),
  url("../fonts/paperless.woff?89srtu") format("woff"),
  url("../fonts/paperless.svg?89srtu#paperless") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "paperless" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-efficiency:before {
  content: "\e906";
}

.icon-errors:before {
  content: "\e907";
}

.icon-gdpr:before {
  content: "\e908";
}

.icon-mfa:before {
  content: "\e909";
}

.icon-service:before {
  content: "\e90a";
}

.icon-compatibility:before {
  content: "\e90b";
}

.icon-accelerate:before {
  content: "\e90c";
}

.icon-automate:before {
  content: "\e90d";
}

.icon-check:before {
  content: "\e90e";
}

.icon-chevron-right:before {
  content: "\e904";
}

.icon-chevron-left:before {
  content: "\e905";
}

.icon-linkedin:before {
  content: "\e902";
}

.icon-twitter:before {
  content: "\e903";
}

.icon-arrow-down:before {
  content: "\e900";
}

.icon-mail:before {
  content: "\e901";
}
