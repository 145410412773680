.advantage {
  width: 50%;
  padding: 0 6.6rem 7rem;
  float: left;

  @media screen and (max-width: $desktop-larger) {
    padding: 0 2rem 6rem 3rem;
  }

  @media screen and (max-width: $desktop) {
    width: 100%;
    padding: 0 0 4rem 2.6rem;
    float: none;
  }

  @media screen and (max-width: $big-phone) {
    padding: 0 0 3.5rem 0;
  }

  &:nth-child(2n) {
    float: right;

    @media screen and (max-width: $desktop) {
      float: none;
    }
  }

  &__inner {
    position: relative;
    background-color: $lightgray;
    padding: 3.6rem 3.8rem 3.2rem 6rem;
    border-radius: 0.8rem;

    @media screen and (max-width: $desktop) {
      padding: 3.2rem 3rem 2.8rem 4.6rem;
    }

    @media screen and (max-width: $big-phone) {
      padding: 3rem 2.5rem 2.8rem;
    }
  }

  &__title {
    position: relative;
    font-size: 2.8rem;
    padding-bottom: 1.8rem;
    margin-bottom: 1.6rem;

    @media screen and (max-width: $under-tablet) {
      font-size: 2.4rem;
      padding-bottom: 1.6rem;
      margin-bottom: 1.4rem;
    }

    &::after {
      content: "";
      width: 2.5rem;
      height: 0.2rem;
      background-color: $blue;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__text {
    font-size: 1.6rem;

    p {
      margin-bottom: 2.2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__icon {
    font-size: 6rem;
    color: $blue;
    position: absolute;
    top: 3.5rem;
    left: -3rem;

    @media screen and (max-width: $desktop) {
      font-size: 5.2rem;
      top: 3rem;
      left: -2.6rem;
    }

    @media screen and (max-width: $big-phone) {
      position: relative;
      top: auto;
      left: auto;
      margin-bottom: 2.4rem;
      display: block;
    }
  }
}