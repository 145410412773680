.footer {
  padding: 3.6rem 0 2.5rem;
  background: linear-gradient(120deg, rgba(11, 36, 84, 1) 0%, rgba(22, 72, 168, 1) 100%);

  &__inner {
    display: flex;

    @media screen and (max-width: $desktop) {
      display: block;
    }
  }

  &__column {
    width: 37.5rem;

    @media screen and (max-width: $desktop-larger) {
      width: auto;
    }
  }

  &__brand {
    width: 10.7rem;
    height: 6.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    margin-bottom: 2.6rem;

    @media screen and (max-width: $under-tablet) {
      width: 9.7rem;
      height: 5.6rem;
    }
  }

  &__copyright {
    font-size: 1.6rem;
    color: $white;
    display: flex;
    margin-top: 5.6rem;

    @media screen and (max-width: $desktop-larger) {
      display: block;
    }

    @media screen and (max-width: $desktop) {
      display: flex;
      margin-top: 2.5rem;
    }

    @media screen and (max-width: $big-phone) {
      display: block;
    }
  }

  &__copyright-timeframe {
    display: block;
  }

  &__copyright-separator {
    margin: 0 0.4rem;

    @media screen and (max-width: $desktop-larger) {
      display: none;
    }

    @media screen and (max-width: $desktop) {
      display: block;
    }

    @media screen and (max-width: $big-phone) {
      display: none;
    }
  }

  &__copyright-link {
    position: relative;
    color: $white;
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    &:hover,
    &:focus {
      color: darken($white, 20%);
    }
  }
}
