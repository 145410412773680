.about-item {
  max-width: 49.2rem;
  padding: 0 3.4rem;

  @media screen and (max-width: $desktop-larger) {
    padding: 0 2.6rem;
  }

  @media screen and (max-width: $desktop) {
    padding: 0 2rem 6rem;
    width: 50%;
  }

  @media screen and (max-width: $under-tablet) {
    width: auto;
    margin: 0 auto;
    max-width: 38rem;
    padding: 0 0 5rem;
  }

  &__icon-wrapper {
    position: relative;
    width: 12rem;
    height: 12rem;
    box-shadow: 0 0 1.6rem rgba(24, 38, 65, 0.5);
    background-color: $blue;
    border-radius: 0.8rem;
    margin: 0 auto 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $large-desktop) {
      width: 10.8rem;
      height: 10.8rem;
    }

    @media screen and (max-width: $desktop) {
      width: 9.6rem;
      height: 9.6rem;
      margin-bottom: 2rem;
    }

    @media screen and (max-width: $under-tablet) {
      width: 8.4rem;
      height: 8.4rem;
      margin-bottom: 1.8rem;
    }
  }

  &__icon {
    font-size: 7.5rem;

    @media screen and (max-width: $large-desktop) {
      font-size: 6.7rem;
    }

    @media screen and (max-width: $desktop) {
      font-size: 6rem;
    }

    @media screen and (max-width: $under-tablet) {
      font-size: 5.2rem;
    }
  }

  &__text {
    text-align: center;

    @media screen and (max-width: $desktop-larger) {
      font-size: 1.6rem;
    }

    a {
      font-weight: $weight-semibold;
      color: $white;
      text-decoration: underline;
      text-underline-offset: 0.3rem;

      &:hover,
      &:focus {
        color: darken($white, 20%);
      }
    }
  }

  &__tooltip {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }

  &__tooltip-button {
    position: relative;
    font-size: 1.5rem;
    color: $black;
    margin-top: 1.4rem;
    padding: 0.6rem 1.7rem;
    background-color: $lightgray;
    border-radius: 2rem;
    display: inline-block;

    &:hover,
    &:focus {
      color: $blue;
    }

    &::after {
      content: "";
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 0.2rem;
      background-color: $black;
      transform-origin: center;
      position: absolute;
      bottom: -2.8rem;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      transition: 0.25s ease all;
      opacity: 0;
      visibility: hidden;

      .open & {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__tooltip-content {
    font-size: 1.6rem;
    color: $black;
    position: absolute;
    top: 6.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 34rem;
    background-color: $lightgray;
    padding: 1.6rem 1.8rem 1.5rem;
    border-radius: 0.8rem;
    z-index: 5;
    box-shadow: 0.5rem 0 1.5rem rgba(24, 38, 65, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: 0.25s ease all;

    .open & {
      opacity: 1;
      visibility: visible;
    }

    a {
      font-weight: $weight-semibold;
      color: $black;
      text-decoration: underline;
      text-underline-offset: 0.3rem;

      &:hover,
      &:focus {
        color: $blue;
      }
    }
  }
}