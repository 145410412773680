.functionalities {
  position: relative;
  padding: 7.5rem 0 9rem;
  background-color: $white;
  overflow: hidden;

  @media screen and (max-width: $desktop) {
    padding: 5.5rem 0;
  }

  @media screen and (max-width: $under-tablet) {
    padding: 4.5rem 0;
  }

  &::before {
    content: "";
    width: 150%;
    height: 115.9rem;
    background: linear-gradient(90deg, rgba(11, 36, 84, 1) 0%, rgba(22, 72, 168, 1) 100%);
    position: absolute;
    top: 0;
    left: -25%;
    border-radius: 0 0 30% 60%;
    transform: skew(-20deg, 0deg);

    @media screen and (max-width: $desktop) {
      width: 200%;
      left: -50%;
      height: 60rem;
    }
  }

  &__inner {
    position: relative;
    z-index: 1;
  }

  &__surtitle {
    font-size: 2rem;
    font-weight: $weight-semibold;
    color: $white;
    margin-bottom: 1.7rem;
    opacity: 0.8;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.8rem;
      margin-bottom: 1.4rem;
    }
  }

  &__title {
    margin-bottom: 7rem;
    color: $white;

    @media screen and (max-width: $desktop) {
      margin-bottom: 5rem;
    }
  }

  &__list {
    margin: 0 -6.6rem -6rem;
    display: flex;
    flex-flow: row wrap;

    @media screen and (max-width: $desktop-larger) {
      margin: 0 -3.5rem -5rem;
    }

    @media screen and (max-width: $desktop) {
      display: block;
      margin: 0 0 -4rem;
    }
  }

  &__more {
    max-width: 66.6rem;
    margin: 7.5rem auto 0;
  }
}