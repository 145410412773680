.footer-navigation {
  display: flex;
  flex-flow: row wrap;
  margin-left: 14.4rem;
  flex: 1;

  @media screen and (max-width: $large-desktop) {
    margin-left: 10rem;
  }

  @media screen and (max-width: $desktop) {
    margin-left: 0;
    margin-top: 5rem;
    margin-bottom: -1.7rem;
  }

  @media screen and (max-width: $under-tablet) {
    margin-top: 4rem;
    margin-bottom: -1.3rem;
  }

  &__list {
    margin-right: 9rem;

    @media screen and (max-width: $desktop) {
      margin-right: 6.5rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__item {
    margin-bottom: 1.7rem;

    @media screen and (max-width: $under-tablet) {
      margin-bottom: 1.3rem;
    }

    &--cta {
      margin-top: 1.5rem;
    }
  }

  &__link {
    color: $white;
    display: inline-block;
    transition: 0.25s ease all;

    @media screen and (max-width: $under-tablet) {
      font-size: 1.6rem;
    }

    .footer-navigation__item--cta & {
      padding: 0.9rem 1.4rem;
      border: 0.1rem solid $white;
      border-radius: 0.4rem;
    }

    &:hover,
    &:focus {
      color: darken($white, 20%);

      .footer-navigation__item--cta & {
        color: $blue;
        background-color: $white;

        &::after {
          display: none;
        }
      }
    }
  }
}