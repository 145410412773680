* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: $text;
  font-size: 1.8rem;
  font-weight: $weight-regular;
  letter-spacing: 0.016rem;
  color: $black;
  margin: 0;
  padding: 0;
  background-color: $white;
}

.container {
  width: 92%;
  margin: 0 auto;
  max-width: 164rem;

  &--medium {
    max-width: 136.4rem;
  }

  &--narrow {
    max-width: 108.8rem;
  }

  &--small {
    max-width: 95rem;
  }
}

::selection {
  background-color: rgba(128, 134, 255, 0.2);
  padding: 10px;
}

a {
  outline: none;
  text-decoration: none;
  transition: 0.25s ease color;

  &:focus-visible {
    outline: 0.1rem dashed $black;
  }
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1;
  margin: 0;
}

h1,
h2 {
  font-size: 4.2rem;

  @media screen and (max-width: $large-desktop) {
    font-size: 3.6rem;
  }

  @media screen and (max-width: $under-tablet) {
    font-size: 3.4rem;
  }
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

p {
  margin: 0 0 2.8rem;
  line-height: 1.25;

  @media screen and (max-width: $under-tablet) {
    margin: 0 0 2.4rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

blockquote {
  margin: 0;
}

/* Skip to content */
.skip-to-content {
  font-family: $text;
  font-size: 1.6rem;
  font-weight: 600;
  position: absolute !important;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
  padding: 0.5rem 1rem;
  color: $blue;
  z-index: 20;

  &:focus {
    overflow: visible;
    clip: auto;
    width: auto;
    height: auto;
    z-index: 30100;
  }
}

.sr-only {
  font-size: 0;
  text-indent: -999rem;
  display: inline-block;
  position: absolute;
}

.center {
  display: flex;
  justify-content: center;
}